import axios from 'axios';
import { useCookies } from 'react-cookie';

interface pagination {
    page: number,
    page_size: number,
    keyword?: string,
    class_type_id?: number;
    class_type_category?: string;
    quarter?: string;
    year?: number;
    type?: string;
    limit?: 'yes' | 'no';
    filter_type?: string;

}
class StagingApi {

    url: string;
    source = axios.CancelToken.source()

    constructor() {
        // this.url = "http://127.0.0.1:8000"
        //  this.url = "https://ifrs.opalcityadvisory.com/api/public"



        //add line 
       this.url = "https://ifrs.zainrohn.com/public"

        const [cookies, _, removeCookie] = useCookies();

        // Add Auth header
        axios.interceptors.request.use((config) => {

            config.headers["Accept"] = "application/json"
            config.headers["Content-Type"] = "application/json"
            config.headers["X-Requested-With"] = "XMLHttpRequest"
            config.cancelToken = this.source.token;
            if (cookies.userinfo) {
                config.headers["Authorization"] = cookies.userinfo.accessToken;
            }
            return (config);

        })

        // Handle 401
        axios.interceptors.response.use((response) => {
            if (!response)
                return Promise.reject(response)
            return response
        }, function (error) {
            if (!error)
                return Promise.reject(error);
            if (401 === error.response?.status) {
                removeCookie("userinfo")
                removeCookie("token")
            } else {
                return Promise.reject(error);
            }
        });
    }
    /**
     * Staging profile APIs
     * @param {}
     */
    staging_profile(): {
        staging_list(query: pagination): any;
        index(query: pagination, id: number,year:number,quarter:string): any;
        questions(query: { class_type_id: number; }): any;
        store(query: { client_id: number; answers: { id: number; value?: number; }[];year:number,quarter:string }): any;

        store_Groupe(query: { client_id: string[]; answers: { id: number; value?: number; }[];year:number|undefined,quarter:string|undefined }): any;

        update_stage(query: { client_id: number; answers: { id: number; value?: number; }[] ,year:number,quarter:string}): any;

        delete(query: { id: number }): any;
   
    } 
    
    {
        var endpoints: any = {}

        endpoints.staging_list = (query: any, name = 'clients/stage') => axios.get(`${this.url}/${name}`, { params: query })

        endpoints.index = (query: any, id: number,year:number,quarter:string, name = 'staging/client-profile/all') => axios.get(`${this.url}/${name}/${id}/${year}/${quarter}`, { params: query })

        endpoints.questions = (query: any, name = 'staging') => axios.get(`${this.url}/${name}/${query.class_type_id}`, { params: query })

        endpoints.store = (query: any, name = 'staging/client-profile') => axios.post(`${this.url}/${name}`, query)


        endpoints.store_Groupe = (query: any, name = 'staging/client-profile/group') => axios.post(`${this.url}/${name}`, query)


        endpoints.update_stage = (query: any, name = 'staging/update_profile') => axios.post(`${this.url}/${name}`, query)

        endpoints.delete = (query: any, name = "staging/delete") => axios.delete(`${this.url}/${name}/${query.id}`, { params: query })

        return endpoints
    }

    abortCalls = () => this.source.cancel('Operation canceled due to route change.')

}

export default StagingApi