

const pageNameMapping = {
    "/all-clients": "Direct Facilities",
    "/institutions": "Financial Institutions",
    "/offbalance": "Off Balance",
    "/limits": "Unused Limits",
  
  };
  
  export function getPageName(url) {
    return pageNameMapping[url] ; 
  }

  