import axios from 'axios';
import { useCookies} from 'react-cookie';

interface pagination {
    page: number,
    page_size: number,
    keyword?: string,
    class_type_id?: number;
    class_type_category?: string;
    quarter?: string;
    year?: number;
    type?: string;
    limit?: 'yes' | 'no';
    filter_type?: string;
}

class IrsApi {

    url: string;
    source = axios.CancelToken.source()

    constructor() {
        // this.url = "http://127.0.0.1:8000"
        //  this.url = "https://ifrs.opalcityadvisory.com/api/public"

        //add line 
       this.url = "https://ifrs.zainrohn.com/public"
        const [cookies, _, removeCookie] = useCookies();

        // Add Auth header
        axios.interceptors.request.use((config) => {

            config.headers["Accept"] = "application/json"
            config.headers["Content-Type"] = "application/json"
            config.headers["X-Requested-With"] = "XMLHttpRequest"
            config.cancelToken = this.source.token;
            if (cookies.userinfo) {
                config.headers["Authorization"] = cookies.userinfo.accessToken;
            }
            return (config);

        })

        // Handle 401
        axios.interceptors.response.use((response) => {
            if (!response)
                return Promise.reject(response)
            return response
        }, function (error) {
            if (!error)
                return Promise.reject(error);
            if (401 === error.response?.status) {
                removeCookie("userinfo")
                removeCookie("token")
            } else {
                return Promise.reject(error);
            }
        });
    }
    /**
     * IRS APIs
     * @param {}
     */
    irs(): {
        index(query: pagination): any;

        indexl(query: pagination): any;
        irs(query: { class_type_id: number; category_id: number; financial_status: string; }): any;
        questions(query: { id: number; }): any;
        store(query: any): any;
        update(query: any): any;
        updatePercentage(query: { class_type_id: number; category_id: number; financial_status: string; percentage: number; }): any;
    } {
        var endpoints: any = {}

        endpoints.index = (query: any, name = 'clients/irs') => axios.get(`${this.url}/${name}`, { params: query })


        endpoints.indexl = (query: any, name = 'clients/irsl') => axios.get(`${this.url}/${name}`, { params: query })

        endpoints.irs = (query: any, name = 'irs/show') => axios.get(`${this.url}/${name}`, { params: query })

        endpoints.store = (query: any, name = 'irs/questions') => axios.post(`${this.url}/${name}`, query)

        endpoints.update = (query: any, name = 'irs/questions') => axios.put(`${this.url}/${name}/${query.id}`, query)

        endpoints.updatePercentage = (query: any, name = 'irs') => axios.post(`${this.url}/${name}`, query)

        return endpoints
    }

    abortCalls = () => this.source.cancel('Operation canceled due to route change.')
}

export default IrsApi