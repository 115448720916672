import SearchClient from '../containers/Clients/SearchClient/SearchClient';
import Clients from '../containers/Clients/Clients';
import ImportClients from '../containers/ImportClients/ImportClients';
import PDs from '../containers/PD/PDs';
import ImportPDs from '../containers/PD/ImportPDs';
import IRS from '../containers/IRS/IRS';
import Settings from '../containers/Settings/Settings';
import ViewIRSs from '../containers/IRS/ViewIRSs';

import ViewIRSsl from '../containers/IRS/ViewIRSsl';



import ViewStaging from '../containers/Staging/ViewStaging';

export const TYPES = [
  { label: 'ON-Balance', value: 'onbalance' },
  { label: 'OFF-Balance', value: 'offbalance' },
];

export const LIMITS = [
  { label: 'LIMITS: ON', value: 'yes' },
  { label: 'LIMITS: OFF', value: 'no' },
];
export const dashboardContent = (section:any) => {
  // debugger;

  switch (section) {
    case 'search-client':
   

      return <SearchClient />;
    case 'all-clients':
      return <Clients category="facility" />;
    case 'import-clients':
  
      return (
        <ImportClients
          type="clients"
          link="/templates/Direct Credit -Template.xlsx"
        />
      );

    case 'institutions':
      return <Clients category="financial" />;
    case 'import-institutions':
      return (
        <ImportClients
          type="banks"
          link="/templates/Bank-Investments-temp.xlsx"
        />
      );
    case 'offbalance':
      return <Clients category="facility" offbalance />;
    case 'import-offbalance':
      return (
        <ImportClients
          type="documents"
          link="/templates/offbalance-template.xlsx"
        />
      );
    case 'limits':
      return <Clients category="facility" type="limits" offbalance />;
    case 'limits-offbalance':
      return <Clients category="facility" type="limits" offbalance />;
    case 'import-limits':
      return (
        <ImportClients type="limits" link="/templates/limit-template.xlsx" />
      );
    case 'all-pds':
      return <PDs />;

    case 'import-pd': {
  
      return (
        <ImportPDs
          type="pds"
          link="/templates/Corp-Middle-SME-PD-template.xlsx"
          link1="/templates/Retail-PD-template.xlsx"
          link2="/templates/Bank-Investments-PD-temp.xlsx"
        />

 
      );
    }


    case 'irs':
      return <IRS />;
    case 'all-irs':
      return <ViewIRSs />;

    case 'all-irs-limits':
      return <ViewIRSsl />;

    case 'all-staging':
      return <ViewStaging />;
    case 'settings':
      return <Settings />;
    default:
      break;
  }
};
