import axios from 'axios';
import { useCookies} from 'react-cookie';


class AuthApi {
  url: string;
  source = axios.CancelToken.source();

  constructor() {
    // this.url = "http://127.0.0.1:8000"
    //  this.url = "https://ifrs.opalcityadvisory.com/api/public"


    //add line
    this.url = "https://ifrs.zainrohn.com/public"

    const [cookies, _, removeCookie] = useCookies();

    // Add Auth header
    axios.interceptors.request.use((config) => {
      config.headers['Accept'] = 'application/json';
      config.headers['Content-Type'] = 'application/json';
      config.headers['X-Requested-With'] = 'XMLHttpRequest';
      config.cancelToken = this.source.token;
      if (cookies.userinfo) {
        config.headers['Authorization'] = cookies.userinfo.accessToken;
      }
      return config;
    });

    // Handle 401
    axios.interceptors.response.use(
      (response) => {
        if (!response) return Promise.reject(response);
        return response;
      },
      function (error) {
        if (!error) return Promise.reject(error);
        if (401 === error.response?.status) {
          removeCookie('userinfo');
          removeCookie('token');
        } else {
          return Promise.reject(error);
        }
      },
    );
  }

  /**
   * Authentication APIs
   * @param {}
   */
  auth(): {
    login(query: { email: string; password: string }, name?: string): any;
    logout(query: null, name?: string): any;
  } {
    debugger;
    var endpoints: any = {};
    debugger;
    endpoints.login = (query: any, name = 'login') =>
      axios.post(`${this.url}/${name}`, query);
    debugger;
    endpoints.logout = (query: any, name = 'logout') =>
      axios.post(`${this.url}/${name}`, query);

    return endpoints;
  }
  abortCalls = () =>
    this.source.cancel('Operation canceled due to route change.');
}

export default AuthApi;
