import { useEffect } from "react"
import { useState } from "react"
import { useTranslation } from "react-multi-lang"
import { EllipsisLoader } from "../../../../components/Loader/Loader"
import Modal from "../../../../components/Modal/Modal"
import { DashboardTable } from "../../../../components/Table/Table"
import StagingApi from "../../../../services/api/staging"
import AddRate from "./AddStage"
import { Confirm } from '../../../../components/Alerts/Alerts'

import { useDispatch, useSelector } from 'react-redux'

import {StagingSlice } from "../../.././Staging/StagingSlice"



interface IProps {
    isOpen: boolean;
    toggle: Function;
    client_id: number;
    class_type: number;



    year: number;
    quarter:string;


    isUpdate:boolean;

}

export default (props: IProps) => {
    
    // Translation
    const t = useTranslation()


    const dispatch = useDispatch()
    
    // Hooks
    const [isLoaded, setIsLoaded] = useState<boolean>(false)

    const [showQuestions, setShowQuestions] = useState<boolean>(false)
    const [editable, setEditable] = useState<boolean>(true)
    const [answers, setAnswers] = useState<number[]>([])
    const [values, setValues] = useState<any>({})

    const [profiles, setProfiles] = useState<any>()


    const [isUpdate, setisUpdate] = useState<boolean>(false)


    // API
    const ENDPOINTS = new StagingApi()
    



 // Delete
 const remove = (id: number) => {
        
    dispatch(StagingSlice.actions.setIsLoading(true) )

    
    ENDPOINTS.staging_profile().delete({ id })
    .then(() => {
       dispatch( StagingSlice.actions.setIsLoading(false) )
       

      

        dispatch( StagingSlice.actions.deleteirs_profile([id]) )

        




        
    })

   

}












    useEffect(() => {
        if(!isLoaded) {
            // Fetch data

//alert(props.year+'22222222')

            ENDPOINTS.staging_profile().index({ page: 1, page_size: 1000 }, props.client_id,2021,'q1')
            .then((response: any) => {
                setProfiles(response.data.data)

                //alert(response.data.data.stage_no+"999")
                
                //alert(response.data.data.year)
              


                setIsLoaded(true)
            })
        }
       // alert(props.year)
    }, [isLoaded])

    const getProfiles = () => {

        return profiles.reduce((profilesObject: any, profile: any) => {
            profilesObject[profile.id] = { date: new Date(profile.created_at).toLocaleDateString('en-GB',{year:'numeric',month:'numeric',day:'numeric'}),stage: profile.stage_no,quarter:profile.quarter,year:profile.year, actions: <div className="show-on-hover">
                
                
                
                
                <i className="icon-edit" onClick={() => {
                let tmp = {...values}
                setAnswers(profile.answers.map((answer: any) => {
                    if(answer.with_value === "Yes")
                        tmp[answer.staging_option_id] = answer.value
                    return answer.staging_option_id
                }))
                
                setValues(tmp)
                
               
                
                setEditable(true)

                setisUpdate(true)

                setShowQuestions(true)
            }} />
            
            
            
            
            
            <i className="icon-delete" onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                                e.stopPropagation()
                                Confirm({
                                    message: t("delete_confirmation"),
                                    onConfirm: () => {remove(profile.id)
                                    
                                        if (props.isOpen) {

                                            ENDPOINTS.staging_profile().index({ page: 1, page_size: 1000 },props.client_id,props.year,props.quarter)
                                            .then((response: any) => {
                                                   
                                                   //alert(props.year+'');
                                                setProfiles(response.data.data)
                                               
                                               
                                                setIsLoaded(true)
                                            })
                                
                                        }
                                    
                                    }
                                   
                                    
                                    
                                }
                               
                                )
                            }
                            
                            
                            } />







            
            
            
            </div> };
           

            //alert(profile.stage_no+'000000000000000')

            return profilesObject;
        }, {});
    }
 
    useEffect(() => {



        if (props.isOpen) {

            ENDPOINTS.staging_profile().index({ page: 1, page_size: 1000 },props.client_id,props.year,props.quarter)
            .then((response: any) => {
                   
                   //alert(props.year+'');
                setProfiles(response.data.data)
               
               
                setIsLoaded(true)
            })

        }











        if(!props.isOpen) {
            setShowQuestions(false)
            setEditable(true)
            setAnswers([])
            setValues({})
        }
    }, [props.isOpen])
     
    return(
        <Modal open={props.isOpen} toggle={props.toggle}>
            { !showQuestions ?
            <>
            { isLoaded ?
            <div style={{ minWidth: 500, textAlign: "left" }} className="profiles">
                <h2 style={{ margin: "0 0 20px", display: "inline-block" }}>{t("client_stages")}</h2>

                <h5  style={{margin: "0 0 20px", display: "block" ,color:"red" }}    >{t("Note: If there is a previous profile, when adding a new profile, the most recent one will be used")}</h5>


                <button className="button bg-gold color-white" style={{ float: "right", position: "relative", top: -7 }} onClick={() => {
                    setShowQuestions(true)
                    setEditable(true)
                }}>{t("add_stage")}</button>
                
                <div style={{ marginBottom: -20 }}>
                    <DashboardTable
                        //header={[t("date"), ""]}



                        header={[t("date"), t("stage"),t("quarter"), t("year"), ""]}



                        body={getProfiles()}
                      
                        />
                </div>
            </div> : <EllipsisLoader /> }
            </> : 
            <AddRate class_type={props.class_type} client_id={props.client_id}  year={props.year}   quarter={props.quarter} defaultAnswers={answers}     isUpdate={isUpdate}     readonly={!editable} defaultValues={values}  /> }
        </Modal>
    )
}