import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Models
export interface client_i_r_s_profiles {
    id: number;
    client_id: number;
    year: number;
    quarter: string;
    created_at:Date,
    updated_at:Date,
}

// pds state
export interface irs_profileState {
    isLoaded: boolean, // First load
    isLoading: boolean,
     // On filtering laoder
   // isFetching: boolean,
    //hasMore: boolean,
    client_i_r_s_profiles: client_i_r_s_profiles[]
    
}

const initialState: irs_profileState = {
    isLoaded: false,
    isLoading: false,
   // isFetching: false,
   // hasMore: true,
   client_i_r_s_profiles: [
        // {
        //     id: 1,
        //     year: 2021,
        //     quarter: "Q1"
        // },
        // {
        //     id: 2,
        //     year: 2021,
        //     quarter: "Q2"
        // },
        // {
        //     id: 3,
        //     year: 2020,
        //     quarter: "Q1"
        // },
        // {
        //     id: 4,
        //     year: 2020,
        //     quarter: "Q2"
        // },
        // {
        //     id: 5,
        //     year: 2020,
        //     quarter: "Q3"
        // },
        // {
        //     id: 6,
        //     year: 2020,
        //     quarter: "Q4"
        // },
        // {
        //     id: 7,
        //     year: 2019,
        //     quarter: "Q1"
        // },
        // {
        //     id: 8,
        //     year: 2019,
        //     quarter: "Q2"
        // }
    ]
}

// pds slice
export const irs_profileSlice = createSlice({
    name: 'client_i_r_s_profiles',
    initialState,
    reducers: {
        setIsLoaded: ( state, {payload}: PayloadAction<boolean> ) => {
            state.isLoaded = payload
        },
        setIsLoading: ( state, {payload}: PayloadAction<boolean> ) => {
            state.isLoading = payload
        },


        deleteirs_profile: ( state, {payload}: PayloadAction<number[]> ) => {
            payload.map(id => {
                let index = state.client_i_r_s_profiles.findIndex( pd => pd.id === id )
                if( index != -1 )
                    state.client_i_r_s_profiles.splice( index, 1 )
            })
        },

       /* 
        setIsFetching: ( state, {payload}: PayloadAction<boolean> ) => {
            state.isFetching = payload
        },
        setHasMore: ( state, {payload}: PayloadAction<boolean> ) => {
            state.hasMore = payload
        },
        addPDs: ( state, {payload}: PayloadAction<pd[]> ) => {
            state.pds = [ ...state.pds, ...payload ]
        },
        updatePD: ( state, {payload}: PayloadAction<pd> ) => {
            let index = state.pds.findIndex( pd => pd.id === payload.id )
            if( index !== -1 )
                state.pds[index] = payload
        },
        deletePDs: ( state, {payload}: PayloadAction<number[]> ) => {
            payload.map(id => {
                let index = state.pds.findIndex( pd => pd.id === id )
                if( index != -1 )
                    state.pds.splice( index, 1 )
            })
        },

        */
       
        reset: ( state ) => {
            state.client_i_r_s_profiles = []
            //state.hasMore = true
        }

        


    }
})