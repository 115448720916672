import { ReactSVG, useEffect, useState } from "react";
import { Checkbox, InputField, NumberField, SimpleCheckbox } from "../../components/FormElements/FormElements";
import { WhiteboxLoader } from "../../components/Loader/Loader";
// API
import SettingsApi from "../../services/api/settings";
// Translation
import { useTranslation } from "react-multi-lang";
import { SelectField} from '../../components/FormElements/FormElements'


export default () => {

    const [activeTab, setActiveTab] = useState(0);

    const [generalFields, setGeneralFields] = useState<{ id: number; value: number | string; description: string; }[]>([]);
    const [savingGenerael, setSavingGeneral] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // Documents
    const [documentsFields, setDocumentsFields] = useState<{ id: number; ccf: number | string; name: string; }[]>([]);

    const [documentsFields1, setDocumentsFields1] = useState<{ id: number; score: number | string; name: string; }[]>([]);

    const [documentsFields2, setDocumentsFields2] = useState<{ id: number; score: number | string; name: string; }[]>([]);

    const [documentsFields3, setDocumentsFields3] = useState<{name: string; category:string;sub_category:string }[]>([]);

    const [savingDocuments, setSavingDocuments] = useState(false);

    const [savingDocuments1, setSavingDocuments1] = useState(false);
    const [savingDocuments2, setSavingDocuments2] = useState(false);
    const [savingDocuments3, setSavingDocuments3] = useState(false);

    // PD & LGD
    const [preDefined, setPreDefined] = useState<any>({});
    const [lgdFields, setLgdFields] = useState<any>({});
    const [lgdColumns, setLgdColumns] = useState<string[]>([]);


    const [Classtype, setClasstype] = useState<string>("");
    const t = useTranslation()
    const [category1, setcategory] = useState<'facility' | 'financial' | null>(null)

    const [category11, setcategoryy] = useState<'3' | '8' | '10'| null>(null)

    
    const [sub_category1, setsubcategory] = useState<'facility' | 'retail' | 'local bank' | 'investments' | 'abroad bank' | 'central bank' | null>(null)

    const ENDPOINTS = new SettingsApi();

    useEffect(() => {
        ENDPOINTS.settings().general()
            .then((res: any) => {
                setGeneralFields(res.data?.data);
                setIsLoading(false);
            });
        ENDPOINTS.settings().documents({ page: 1, page_size: 1000 })
            .then((res: any) => {
                setDocumentsFields(res.data?.data?.document_types);
                setIsLoading(false);
            });




            ENDPOINTS.settings().documents1({ page: 1, page_size: 1000 })
            .then((res: any) => {
                setDocumentsFields1(res.data?.data?.score_ranges);
                setIsLoading(false);
            });

            ENDPOINTS.settings().documents2({ page: 1, page_size: 1000 })
            .then((res: any) => {
                setDocumentsFields2(res.data?.data?.retial_score_ranges);
                setIsLoading(false);
            });

        ENDPOINTS.settings().predefined({ page: 1, page_size: 1000 })
            .then((res: any) => {
                let tempPreDefined = { ...preDefined };
                res.data?.data['pre-defined']?.map((item: any) => {
                    if(!tempPreDefined[item.class_type_name]) tempPreDefined[item.class_type_name] = {};
                    if(!tempPreDefined[item.class_type_name][item.stage_name]) tempPreDefined[item.class_type_name][item.stage_name] = [];
                    tempPreDefined[item.class_type_name][item.stage_name].push({
                        id: item.id,
                        grade: item.grade_name,
                        pd: item.pd,
                        lgd: item.lgd,
                        currency_type: item.currency_type
                    });
                });
                setPreDefined(tempPreDefined);
            });
        ENDPOINTS.settings().lgd({ page: 1, page_size: 1000 })
            .then((res: any) => {
                let tempLgd = { ...lgdFields };
                let tempLgdColumns = [...lgdColumns];
                res.data?.data['lgd-guarantee']?.map((item: any) => {
                    if(!tempLgd[item.guarantee_name]) tempLgd[item.guarantee_name] = {};
                    if(!tempLgd[item.guarantee_name][item.stage_name]) tempLgd[item.guarantee_name][item.stage_name] = [];
                    tempLgd[item.guarantee_name][item.stage_name].push(item);
                    if(!tempLgdColumns.includes(item.class_type_name))
                        tempLgdColumns.push(item.class_type_name)
                });
                setLgdFields(tempLgd);
                setLgdColumns(tempLgdColumns);
            });
    }, []);

    return (
        <>
            {isLoading ? <WhiteboxLoader /> :
                <div>
                    <ul className="tabs">
                        {["General", "Credit Conversion Factors(CCF)", "PD & LGD","Score With FS","Score whithout FS","Add New portfolio "].map((item, index) => <li className={activeTab === index ? 'active' : ''} onClick={() => setActiveTab(index)}>{item}</li>)}
                    </ul>

                    {/* General */}
                    { activeTab === 0 &&
                    <div style={{ maxWidth: 400, marginTop: 40 }}>
                        {generalFields?.map((field, index) =>
                        <>
                        { field.id == 1 || field.id == 3 ?
                        <Checkbox checked={field.value === 1} label={field.description.replace(' (0: false, 1:true)', '')} onChange={(e: any) => {
                            setGeneralFields(prev => {
                                const temp = [...prev];
                                temp[index].value = e.target.checked ? 1 : 0;
                                return temp;
                            })
                        }} /> :
                        generalFields.find(item => item.id === 3)?.value === 0 && [4, 5, 6].includes(field.id) ?
                        '' :
                        <NumberField label={field.description} value={field.value} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setGeneralFields(prev => {
                                const temp = [...prev];
                                temp[index].value = e.target.value;
                                return temp;
                            })
                        }} /> }
                        </>
                        )}
                        <br /><br />
                        <button className="button bg-gold color-white" style={{ padding: '0 50px' }} disabled={savingGenerael} onClick={() => {
                            setSavingGeneral(true);
                            Promise.all(generalFields.map(field => ENDPOINTS.settings().saveGeneral({ id: field.id, value: field.value })))
                                .then(() => setSavingGeneral(false));
                        }}>{savingGenerael ? 'Saving...' : 'Save'}</button>
                        <br /><br />
                    </div> }

                    {/* Documents */}
                    { activeTab === 1 &&
                    <div style={{ maxWidth: 400, marginTop: 40 }}>
                        {documentsFields?.map((field, index) => <NumberField label={field.name} value={field.ccf} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setDocumentsFields(prev => {
                                const temp = [...prev];
                                temp[index].ccf = e.target.value;
                                return temp;
                            })
                        }} />)}
                        <br /><br />
                        <button className="button bg-gold color-white" style={{ padding: '0 50px' }} disabled={savingDocuments} onClick={() => {
                            setSavingDocuments(true);
                            Promise.all(documentsFields.map(field => ENDPOINTS.settings().saveDocuments({ id: field.id, ccf: field.ccf })))
                                .then(() => setSavingDocuments(false));
                        }}>{savingDocuments ? 'Saving...' : 'Save'}</button>
                        <br /><br />
                    </div> }


                    {/* PD & LGD */}
                    { activeTab === 2 &&
                    <>
                    <h3>Guarantee</h3>
                    {
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Stage</th>
                                    {lgdColumns.map(col => <th>{col}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.keys(lgdFields).map(key => (
                                        <>
                                            <tr><td style={{ paddingLeft: 20, fontWeight: 'bold', fontSize: 18 }} colSpan={lgdColumns.length + 1}>{key}</td></tr>
                                            {
                                                Object.keys(lgdFields[key]).map(stageKey => (
                                                    <tr>
                                                        <td style={{ paddingLeft: 20 }}>{stageKey}</td>
                                                        {lgdColumns.map(col => <td style={{ padding: '0 10px' }}>{lgdFields[key][stageKey].find((item: any) => item.class_type_name === col) &&
                                                        <NumberField label="Value" value={lgdFields[key][stageKey].find((item: any) => item.class_type_name === col).value} style={{ background: "#FFF", border: "1px solid #DDD" }} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            ENDPOINTS.settings().saveLgd({ id: lgdFields[key][stageKey].find((item: any) => item.class_type_name === col).id, value: +e.target.value });
                                                            const temp = { ...lgdFields };
                                                            temp[key][stageKey][lgdFields[key][stageKey].findIndex((item: any) => item.class_type_name === col)].value = +e.target.value;
                                                            setLgdFields(temp);
                                                        }} />
                                                        }</td>)}
                                                    </tr>
                                                ))
                                            }
                                        </>
                                    ))
                                }
                            </tbody>
                        </table>
                    }
                    {
                        Object.keys(preDefined).map((key) => (
                            <>
                            <h3>{key}</h3>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{ minWidth: 100 }}>Grade</th>
                                        {
                                            preDefined[key][Object.keys(preDefined[key])[0]]?.map((item: any) => <th>{key === 'Central Bank' ? item.currency_type : item.grade}</th>)
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Object.keys(preDefined[key]).map((stageKey) => (
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>{stageKey}</td>
                                                { preDefined[key][stageKey]?.map((item: any, index: any) => 
                                                <td style={{ padding: '0 10px' }}>
                                                    { item.pd !== null &&
                                                    <NumberField label="PD" value={item.pd === -1 ? undefined : item.pd} style={{ background: "#FFF", border: "1px solid #DDD" }} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        ENDPOINTS.settings().savePredefined({ id: item.id, pd: +e.target.value, lgd: item.lgd });
                                                        const temp = { ...preDefined };
                                                        temp[key][stageKey][index].pd = +e.target.value;
                                                        setPreDefined(temp);
                                                    }} />
                                                    }
                                                    <NumberField label="LGD" value={item.lgd == -1 ? undefined : item.lgd} style={{ background: "#FFF", border: "1px solid #DDD" }} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        ENDPOINTS.settings().savePredefined({ id: item.id, lgd: +e.target.value, pd: item.lgd });
                                                        const temp = { ...preDefined };
                                                        temp[key][stageKey][index].lgd = +e.target.value;
                                                        setPreDefined(temp);
                                                    }} />
                                                </td>
                                                )}
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            </>
                        ))
                    }
                    <br /><br />
                    </> }






    {/* Score Rangs  With FinancialStatus */}
   
    { activeTab === 3 &&

<>
     <h3>Ranges With FinancialStatus </h3>

                    <div style={{ maxWidth: 400, marginTop: 40 }}>
                        
                        {documentsFields1?.map((field, index) => <NumberField label={field.name} value={field.score} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setDocumentsFields1(prev => {
                                const temp = [...prev];
                                temp[index].score = e.target.value;
                                return temp;
                            })
                        }}  /> )
                        
                        
                        }
                        <br /><br />



                  



                        <button className="button bg-gold color-white" style={{ padding: '0 50px' }} disabled={savingDocuments1} onClick={() => {
                            setSavingDocuments1(true);
                            Promise.all(documentsFields1.map(field => ENDPOINTS.settings().saveDocuments1({ id: field.id, score: field.score})))
                                .then(() => setSavingDocuments1(false));
                        }}>{savingDocuments1 ? 'Saving...' : 'Save'}</button>
                        <br /><br />
                    </div>




                     
                    
                    <br /><br />
                    </>
                    }










{/* Score Rangs  whithout With FinancialStatus */}
 
{ activeTab === 4 &&

<>
     <h3>Ranges whithout FinancialStatus </h3>

                    <div style={{ maxWidth: 400, marginTop: 40 }}>
                        
                        {documentsFields2?.map((field, index) => <NumberField label={field.name} value={field.score} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setDocumentsFields2(prev => {
                                const temp = [...prev];
                                temp[index].score = e.target.value;
                                return temp;
                            })
                        }}  /> )}
                        <br /><br />



                  



                        <button className="button bg-gold color-white" style={{ padding: '0 50px' }} disabled={savingDocuments2} onClick={() => {
                            setSavingDocuments2(true);
                            Promise.all(documentsFields2.map(field => ENDPOINTS.settings().saveDocuments2({ id: field.id, score: field.score})))
                                .then(() => setSavingDocuments2(false));
                        }}>{savingDocuments2 ? 'Saving...' : 'Save'}</button>
                        <br /><br />
                    </div>




                     
                    
                    <br /><br />
                    </>
                    }

                   




{/* add class type */}
 
{ activeTab === 5 &&

<>
     <h3> Add  New Portfolio  </h3>

   

                    <div style={{ maxWidth: 400, marginTop: 40 }}>
                          {/*
                        {documentsFields2?.map((field, index) => <NumberField label={field.name} value={field.score} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setDocumentsFields2(prev => {
                                const temp = [...prev];
                                temp[index].score = e.target.value;
                                return temp;
                            })
                        }}  /> )}
                        <br /><br />
*/}

              <InputField
                    value={Classtype}
                    type="text"
                    placeholder={t('portfolio name')}
                  
                   
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setClasstype(e.currentTarget.value);
                       
                        
                    } } />
                  


                  <br /><br />






              



              <SelectField  onChange={(selected: { value: '3' | '8' | '10'; }) => setcategoryy(selected.value)} bg="white" placeholder={t("number of pd grade")} options={[
                { label: "from 1 to 3", value: "3" },
                //{ label: "1>>7", value: "7" },

                
                //{ label: "from 1 to 8", value: "8" },
                { label: "from 1 to 10", value: "10" }
            
                
            ]
            
            } />




<br /><br />











              
{/*}


                  <SelectField  onChange={(selected: { value: 'facility' | 'financial'; }) => setcategory(selected.value)} bg="white" placeholder={t("category")} options={[
                    { label: "facility", value: "facility" },
                    { label: "financial", value: "financial" },
                
                    
                ]
                
                } />


<br /><br />
<br /><br />


































<SelectField  onChange={(selected: { value: 'facility' | 'retail' | 'local bank' | 'investments' | 'abroad bank' | 'central bank'; }) => setsubcategory(selected.value)} bg="white" placeholder={t("subcategory")} options={[
                    { label: "facility", value: "facility" },
                    { label: "retail", value: "retail" },
                    { label: "local bank", value: "local bank" },
                    { label: "investments", value: "investments" },
                    { label: "abroad bank", value: "abroad bank" },
                    { label: "central bank", value: "central bank" },
                
                    
                ]
                
                } />

<br /><br />
<br /><br />


*/}

<br /><br />
<br /><br />

                        <button className="button bg-gold color-white" style={{ padding: '0 50px' }} disabled={savingDocuments3} 
                        
                        
                        
                        onClick={() => {
                          
                            setSavingDocuments3(true);

                           // alert('111'+Classtype);
                           // alert('222'+category1);
                           // alert('333'+sub_category1);




{/*}
                            Promise.all(documentsFields3.map(field => ENDPOINTS.settings().saveDocuments3({name: field.name,category:field.category,sub_category:field.sub_category})))
                                .then(() => setSavingDocuments3(false));

                        
                        */}
                                Promise.all(([ENDPOINTS.settings().saveDocuments3({name: Classtype,index:category11+"",category:category1+"",sub_category:sub_category1+""})]))
                                .then(() => setSavingDocuments3(false));  




                        }}>
                            
                            
                            {savingDocuments3 ? 'Saving...' : 'Save'}
                        
                        
                        
                        
                        
                        </button>
                        <br /><br />
                    </div>




                     
                    
                    <br /><br />
                    </>
                    }


                </div>
            }
        </>
    );
}