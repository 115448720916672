export default {
    labelIdle: 'اسحب الملفات وأفلتها أو <span class="filepond--label-action"> تصفح </span>',
    labelInvalidField: 'يحتوي الحقل على ملفات غير صالحة',
    labelFileWaitingForSize: 'في انتظار الحجم',
    labelFileSizeNotAvailable: 'الحجم غير متوفر',
    labelFileLoading: 'تحميل',
    labelFileLoadError: 'خطأ أثناء التحميل',
    labelFileProcessing: 'رفع الملفات',
    labelFileProcessingComplete: 'تم الرفع',
    labelFileProcessingAborted: 'تم إلغاء الرفع',
    labelFileProcessingError: 'خطأ أثناء الرفع',
    labelFileProcessingRevertError: 'خطأ أثناء الاسترجاع',
    labelFileRemoveError: 'خطأ أثناء الحذف',
    labelTapToCancel: 'إضغط للالغاء',
    labelTapToRetry: 'إضغط لإعادة المحاولة',
    labelTapToUndo: 'إضغط للتراجع',
    labelButtonRemoveItem: 'حذف',
    labelButtonAbortItemLoad: 'إلغاء',
    labelButtonRetryItemLoad: 'إعادة المحاولة',
    labelButtonAbortItemProcessing: 'إلغاء',
    labelButtonUndoItemProcessing: 'تراجع',
    labelButtonRetryItemProcessing: 'إعادة المحاولة',
    labelButtonProcessItem: 'رفع',
    labelMaxFileSizeExceeded: 'الملف كبير جدا',
    labelMaxFileSize: 'الحجم الأقصى للملف هو {filesize}',
    labelMaxTotalFileSizeExceeded: 'تم تجاوز الحد الأقصى للحجم الإجمالي',
    labelMaxTotalFileSize: 'الحد الأقصى لحجم الملف الإجمالي هو {filesize}',
    labelFileTypeNotAllowed: 'File of invalid type',
    fileValidateTypeLabelExpectedTypes: 'متوقع {allButLastType} أو {lastType}',
    imageValidateSizeLabelFormatError: 'نوع الصورة غير مدعوم',
    imageValidateSizeLabelImageSizeTooSmall: 'الصورة صغيرة جدًا',
    imageValidateSizeLabelImageSizeTooBig: 'الصورة كبيرة جدًا',
    imageValidateSizeLabelExpectedMinSize: 'الحجم الأدنى هو {minWidth} × {minHeight}',
    imageValidateSizeLabelExpectedMaxSize: 'الحجم الأعلى هو {maxWidth} × {maxHeight}',
    imageValidateSizeLabelImageResolutionTooLow: 'الدقة قليلة جدا',
    imageValidateSizeLabelImageResolutionTooHigh: 'الدقة عالية جدا',
    imageValidateSizeLabelExpectedMinResolution: 'الدقة الأدنى هي {minResolution}',
    imageValidateSizeLabelExpectedMaxResolution: 'الدقة الأعلى هي {maxResolution}'
};
