import React from 'react';
import { useTranslation } from 'react-multi-lang';

// Stylesheet
import './Dashboard.css';

// Cookies
import { useCookies } from 'react-cookie';

// Components
import { SideNav, TopNav } from '../../components/Nav/Nav';

import { dashboardContent } from '../../action/search';

export default (props: any) => {
  // debugger;
  const t = useTranslation();

  const [cookies] = useCookies(['userinfo']);

  const navList = [
    {
      icon: 'icon-search-1',
      name: t('search_client'),
      link: '/search-client',
      show: true,
    },
    {
      icon: 'icon-users',
      name: t('direct_credit_facilities'),
      sub_name: 'Corporate - SME - Retail',
      show: true,
      childs: [
        {
          icon: 'icon-hammer',
          name: t('all_clients'),
          link: '/all-clients',
          show: true,
        },
        {
          icon: 'icon-tasks',
          name: t('import_clients'),
          link: '/import-clients',
          show: true,
        },
      ],
    },
    {
      icon: 'icon-users',
      name: t('Financial institutions'),
      sub_name: 'Banks - investments',
      show: true,
      childs: [
        {
          icon: 'icon-hammer',
          name: t('all_clients'),
          link: '/institutions',
          show: true,
        },
        {
          icon: 'icon-tasks',
          name: t('import_clients'),
          link: '/import-institutions',
          show: true,
        },
      ],
    },
    {
      icon: 'icon-users',
      name: t('OFF-Balance'),
      sub_name: 'Corporate - SME - Retail',
      show: true,
      childs: [
        {
          icon: 'icon-hammer',
          name: t('all_clients'),
          link: '/offbalance',
          show: true,
        },
        {
          icon: 'icon-tasks',
          name: t('import_clients'),
          link: '/import-offbalance',
          show: true,
        },
      ],
    },
    {
      icon: 'icon-users',
      name: t('Unused limits'),
      show: true,
      childs: [
        {
          icon: 'icon-hammer',
          name: t('view_all'),
          link: '/limits',
          show: true,
        },
        {
          icon: 'icon-tasks',
          name: t('import_clients'),
          link: '/import-limits',
          show: true,
        },
      ],
    },
    {
      icon: 'icon-overview',
      name: t('pd'),
      show: true,
      childs: [
        {
          icon: 'icon-product',
          name: t('import'),
          link: '/import-pd',
          show: true,
        },

        {
          icon: 'icon-hammer',
          name: t('view_all'),
          link: '/all-pds',
          show: true,
        },
      ],
    },
    {
      icon: 'icon-star',
      name: t('irs'),
      show: true,
      link: '/all-irs',
    },

    {
      icon: 'icon-hammer',
      name: t('irs-limits'),
      show: true,
      link: '/all-irs-limits',
    },

    {
      icon: 'icon-star',
      name: t('staging'),
      show: true,
      link: '/all-staging',
    },
    {
      icon: 'icon-gears',
      name: t('settings'),
      show: cookies?.userinfo?.role?.name === 'Admin',
      childs: [
        {
          icon: 'icon-product',
          name: t('constants'),
          link: '/settings',
          show: true,
        },
        {
          icon: 'icon-product',
          name: t('irs'),
          link: '/irs',
          show: true,
        },
      ],
    },
  ];

  let section = props.match.params.section
    ? props.match.params.section.toLowerCase()
    : 'search-client';
 
  return (
    <div className="dashboard-page">
      <SideNav list={navList} active={section} />
      <div className="main-side">
        <TopNav />

        <div className="content">{dashboardContent(section)}</div>
      </div>
    </div>
  );
};
